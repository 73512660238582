body {
    /* margin-top: 51px; */
    /*margin-bottom: 50px;*/
    /* background-color: rgb(119, 119, 119); */
    background-color: #ffffff;
    color: rgb(85, 85, 85);
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* BS Overwrites */
.header {
    background-color: #ffffff;
}

.footer {
    color: rgb(51, 51, 51);
}

.footer p {
    margin-top: 15px;
}

.navbar-nav {
    margin: 0;
}

.navbar-nav > li {
    float: none;
    display: inline-block;
}

.navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
}

.navbar-nav > li > .dropdown-menu {
    border-radius: 0;
}

.navbar-nav .dropdown .dropdown-toggle {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}

.navbar-nav .dropdown .active {
    border-left: 1px solid #d58512;
    border-right: 1px solid #d58512;
    background-color: #f0ad4e !important;
    color: #ffffff !important;
}

.navbar-logo {
    padding: 0 !important;
}

.nav-tabs {
    border-bottom: 1px solid #777;
}

.nav-tabs > li {
    margin-bottom: -2px;
}

.nav-tabs > li > a {
    border-radius: 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    border-color: #777;
    border-bottom: 2px solid #fff;
    border-top: 3px solid #ffa500;
    padding-top: 7px;
}

.nav-tabs .router-link-active,
.nav-tabs .router-link-active:focus,
.nav-tabs .router-link-active:hover {
    border-color: #777;
    border-bottom: 2px solid #fff;
    border-top: 3px solid #ffa500;
    padding-top: 7px;
    background-color: #fff;
}

.nav-tabs > li > a:hover {
    border-bottom: 1px;
}

hr {
    margin-top: 10px;
    margin-bottom: 2px;
    border-color: #777;
    background-color: #777;
    color: #777;
}

/* globals */

.show-block {
    display: block !important;
}

.show-inline {
    display: inline !important;
}

.show-inline-block {
    display: inline-block !important;
}

.logo-text {
    font-weight: bold;
}

.border-right {
    border-right: 1px solid #ddd;
}

.border-left {
    border-left: 1px solid #ddd;
}

.padding-bottom {
    padding-bottom: 10px;
}

.no-left {
    padding-left: 0;
}

.no-right {
    padding-right: 0;
}

.no-padding {
    padding: 0;
}

/* components */

.message {
    padding: 0;
    left: 30px;
    right: 30px;
    position: absolute;
    top: 20px;
    max-width: none;
    z-index: 2000;
}

.message .alert {
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

/* recaptcha fix  */

@media (max-width: 480px) {
    #rc-imageselect,
    .g-recaptcha {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

/* home page */

.home-hero {
    border-top: 2px solid rgb(51, 51, 51);
    border-bottom: 1px solid #ffffff;
    background-size: auto;
    color: #ffffff;
    font-size: 22px;
    line-height: 32px;
    background: rgb(51, 51, 51) url('//res.cloudinary.com/weathertools/image/upload/f_auto/v1449452158/site/hero.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 0;
    height: 100vh;
}

/* @media (max-width: 480px) {
    .home-hero {
        background: rgb(51, 51, 51)
            url('//res.cloudinary.com/weathertools/image/upload/f_auto,c_crop,g_west,h_1400,w_480/v1449452158/site/hero.jpg') no-repeat;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .home-hero {
        background: rgb(51, 51, 51)
            url('//res.cloudinary.com/weathertools/image/upload/f_auto,c_crop,g_west,h_1000,w_768/v1449452158/site/hero.jpg') no-repeat;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .home-hero {
        background: rgb(51, 51, 51)
            url('//res.cloudinary.com/weathertools/image/upload/f_auto,c_crop,g_west,h_1000,w_992/v1449452158/site/hero.jpg') no-repeat;
    }
}

@media (min-width: 993px) {
    .home-hero {
        background: rgb(51, 51, 51) url('//res.cloudinary.com/weathertools/image/upload/f_auto,h_900,c_scale/v1449452158/site/hero.jpg')
            no-repeat;
    }
} */

.home-hero h1 {
    text-align: center;
    text-shadow: 1px 2px 0 rgba(51, 51, 51, 0.4);
}

.home-hero p {
    margin-top: 20px;
    text-shadow: 0 1px 0 rgba(51, 51, 51, 0.4);
}

.home-hero ul {
    padding-left: 75px;
}

.home-hero-link {
    color: #ffffff;
    text-decoration: underline;
}

.home-hero a:hover {
    color: #ffffff;
}

.about {
    background-color: rgb(119, 119, 119);
    color: #ffffff;
}

.about a {
    color: #ffffff;
    text-decoration: underline;
}

.about a:hover {
    color: #ffffff;
}

.network {
    padding: 0;
    position: absolute;
    bottom: 50px;
    top: 50px;
    width: 100%;
    height: 100%;
}

.network iframe {
    width: 100%;
    height: 100%;
}

.contact {
    padding-top: 30px;
}

.contact .panel {
    color: rgb(119, 119, 119);
}

.contact form {
    margin-top: 30px;
}

.login {
    padding-top: 30px;
}

.login .panel {
    color: rgb(119, 119, 119);
}

.login form {
    margin-top: 30px;
}

.admin {
    background-color: #fff;
    color: rgb(119, 119, 119);
}

#root {
    overflow-x: hidden;
    overflow-y: hidden;
}

/* app page base */
.app {
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: 110px;
}

.app-tabs {
    padding-top: 10px;
    padding-bottom: 10px;
    top: 52px;
    background-color: #ffffff;
    border-bottom-width: 0px;
}

/* .app-view {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 80vh;
} */

.app-overlay {
    overflow: hidden;
    z-index: 1000;
    opacity: 0.5;
    border: 1px solid #000;
    background: url('//res.cloudinary.com/weathertools/image/upload/v1456988131/site/gears.svg') no-repeat center #555;
}

@media (max-width: 480px) {
    .app-tab-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
    }
}

/*.app-tab-wrapper {*/
/*padding-left: 5px;*/
/*}*/

.app-tab-wrapper > ul {
    min-width: 415px;
}

.station-item {
    border-bottom: 2px solid #777;
}

.station-item .station-item-tabs {
    padding-bottom: 10px;
}

.station-item .station-item-tabs > .nav-tabs {
    background-color: #ddd;
    border-top: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
    border-color: #777;
}

.station-item .today-temp {
    font-size: 30px;
}

.station-item .today-degree {
    font-size: 16px;
    position: relative;
    top: -10px;
}

.station-item .today-high-low {
    font-size: 16px;
    /*font-weight: bold;*/
    padding-left: 20px;
    display: inline-block;
}

.station-item .today-high-low .high {
    color: #a94442;
    border-right: 2px solid #777;
    padding-right: 6px;
}

.station-item .today-high-low .low {
    color: #31708f;
    padding-left: 3px;
}

.station-item .data-table {
    text-align: left;
    font-size: 12px;
    text-overflow: ellipsis;
    padding-top: 10px;
}

.station-item .data-table .data-label {
    width: 30px;
    padding-right: 4px;
    font-weight: bold;
}

.station-item .section {
    padding-bottom: 10px;
    min-height: 140px;
}

.station-item .yesterday .row {
    padding-top: 10px;
}

.station-item .forecast-days {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 5px;
}

.station-item .forecast-days > ul {
    width: 1950px;
}

.station-item .forecast-days > ul > li {
    padding-right: 0;
    padding-left: 0;
    width: 130px;
}

.station-item .light-rain {
    background: no-repeat url('//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/light-rain.png');
    background-position: right 4px bottom 40px;
}

.station-item .rain {
    background: no-repeat url('//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/rain.png');
    background-position: right 4px bottom 40px;
}

.station-item .partly-cloudy {
    background: no-repeat url('//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/partly-cloudy.png');
    background-position: right 4px bottom 40px;
}

.station-item .cloudy {
    background: no-repeat url('//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/cloudy.png');
    background-position: right 4px bottom 40px;
}

.station-item .thunderstorm {
    background: no-repeat url('//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/thunderstorm.png');
    background-position: right 4px bottom 40px;
}

.station-item .thunderstorm-rain {
    background: no-repeat url('//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/thunderstorm-rain.png');
    background-position: right 4px bottom 40px;
}

.station-item .fog {
    background: no-repeat url('//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/fog.png');
    background-position: right 4px bottom 40px;
}

.station-item .snow {
    background: no-repeat url('//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/snow.png');
    background-position: right 4px bottom 40px;
}

.station-item .sun {
    background: no-repeat url('//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/sun.png');
    background-position: right 4px bottom 40px;
}

.station-item .wind {
    background: no-repeat url('//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/wind.png');
    background-position: right 4px bottom 40px;
}

.station-item .forecast-days-title {
    display: block;
    border-radius: 0;
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    padding: 10px 25px;
    background-color: #ddd;
    color: #337ab7;
    text-decoration: none;
}

.station-item .forecast-days > ul > li.active > a,
.station-item .forecast-days > ul > li.active > a:focus,
.station-item .forecast-days > ul > li.active > a:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #777;
    border-bottom: 2px solid #fff;
    border-top: 3px solid #ffa500;
    padding-top: 8px;
}

.station-item .forecast-days-arrow {
    cursor: pointer;
    position: absolute;
    top: 115px;
    width: 18px;
    height: 62px;
    display: block;
    background-color: #fff;
    border: 1px solid #d7d7d7;
    text-align: center;
    padding-top: 20px;
    opacity: 0.4;
}

.station-item .forecast-days-arrow.left {
    left: 15px;
}

.station-item .forecast-days-arrow.right {
    right: 15px;
}

.forecast-day .summary {
    font-weight: bold;
    font-size: 13px;
    padding-bottom: 5px;
}

.forecast-hours {
    padding-left: 15px;
    padding-right: 5px;
    height: 35px;
    margin-bottom: 10px;
    margin-right: 0;
    margin-left: 0;
    line-height: 38px;
    background-color: #ddd;
    font-size: 12px;
}

.forecast-hours > div {
    display: inline-block;
}

.forecast-hours-title {
    font-weight: bold;
    background-color: #fff;
    border-bottom: 1px solid;
    margin-bottom: 10px;
    font-size: 14px;
}

.forecast-hours-row .btn {
    margin-top: 7px;
}

.forecast-hours-more-button {
    margin-top: -15px;
    margin-left: 0;
    margin-right: 0;
    border-top: 0;
}

.forecast-hours-more {
    margin-top: -10px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-top: 0;
}

.new-alert {
    border: 1px solid;
    padding: 10px;
    margin-bottom: 10px;
    padding-bottom: 50px;
}

.form-group {
    padding-right: 4px;
}

.form-group .radio label {
    width: 100%;
}

.form-group .radio .input-text {
    margin-top: -6px;
}

.alerts {
    margin-bottom: 20px;
}

.alerts .alert-item {
    min-height: 80px;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    padding: 8px;
    margin-right: 0;
    margin-left: 0;
}

.alerts .alert-item:last-child {
    border-bottom: 1px solid;
}

.alerts .alert-item .title {
    margin-bottom: 6px;
}

.alerts .alert-item .station {
    /*padding-bottom: 10px;*/
    font-size: 16px;
    font-weight: bold;
    overflow-x: hidden;
    overflow-y: hidden;
}

.alerts .alert-item .text {
    /*padding-bottom: 10px;*/
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.alerts .alert-item .snooze-popup {
    padding-left: 10px;
}

.alerts .alert-item form {
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid #555;
}

.alerts .alert-item .control-label {
    padding-top: 6px;
}

.save-form {
    padding-top: 7px;
    padding-right: 12px;
}

/*
    Everything below is the loader
*/
/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgba(0,0,0,0.3);*/
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 18px;
    width: 10px;
    height: 10px;
    margin-top: -0.5em;
    -webkit-animation: spinner 2000ms infinite linear;
    -moz-animation: spinner 2000ms infinite linear;
    -ms-animation: spinner 2000ms infinite linear;
    -o-animation: spinner 2000ms infinite linear;
    animation: spinner 2000ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
        rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0,
        rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
        rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0,
        rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

.advisory {
    margin-top: 20px;
    margin-bottom: 20px;
}

.advisory-detail ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid;
    border-color: #777 #6c6c6c #666;
    border-radius: 5px;
}

.advisory-status {
    width: 20px;
    height: 20px;
    margin: auto;
    border-radius: 10px;
}

.advisory-status-green {
    color: #3c763d;
    background-color: #008000;
    border: 1px solid #555555;
}

.advisory-status-yellow {
    color: #8a6d3b;
    background-color: #ffff00;
    border: 1px solid #555555;
}

.advisory-status-red {
    color: #a94442;
    background-color: #ff0000;
    border: 1px solid #555555;
}

.advisory-details ul {
    margin-bottom: 0;
}

.advisory-details li {
    font-size: 10px;
    padding: 3px;
}

.advisory-details-green {
    min-width: 118px;
    background-color: #008000;
    color: #ffffff;
}

.advisory-details-yellow {
    min-width: 118px;
    background-color: #ffff00;
}

.advisory-details-red {
    min-width: 118px;
    background-color: #ff0000;
    color: #ffffff;
}

.advisory-key {
    width: 200px;
    margin-top: 15px;
}

.advisory-key > tbody > tr > td {
    border: none;
    padding: 3px;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
